import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, normalizeClass as _normalizeClass, renderSlot as _renderSlot, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-80a904b0"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "single-line"
}
const _hoisted_2 = ["src"]
const _hoisted_3 = ["type", "placeholder", "value", "readonly"]
const _hoisted_4 = ["src"]
const _hoisted_5 = ["placeholder", "readonly", "maxlength"]
const _hoisted_6 = {
  key: 0,
  class: "error-area"
}
const _hoisted_7 = { class: "error-message" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MenuOptionDropdown = _resolveComponent("MenuOptionDropdown")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["input validation-textfield", { readonly: _ctx.readonly }])
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["input-area", {
        'none-border': _ctx.noneBorder,
        error: _ctx.errorMessage && !_ctx.hasFocus,
        focus: _ctx.hasFocus,
      }])
    }, [
      (!_ctx.isMultiline)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            (_ctx.prefixIcon)
              ? (_openBlock(), _createElementBlock("img", {
                  key: 0,
                  src: _ctx.prefixIcon,
                  alt: "prefix icon",
                  class: "prefix-icon"
                }, null, 8, _hoisted_2))
              : _createCommentVNode("", true),
            _createElementVNode("input", {
              type: _ctx.type,
              placeholder: _ctx.placeholder,
              value: _ctx.inputValue + _ctx.suffixValue,
              onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.inputValue = $event.target?.value?.replace(_ctx.suffixValue, ''))),
              onChange: _cache[1] || (_cache[1] = ($event: any) => (
            _ctx.inputValue = $event.target?.value
              ?.replace(/^\s+/, '')
              .replace(/\s+$/, '')
          )),
              onFocus: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onFocus && _ctx.onFocus(...args))),
              onBlur: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.onBlur && _ctx.onBlur(...args))),
              readonly: _ctx.readonly
            }, null, 40, _hoisted_3),
            (_ctx.suffixIcon)
              ? (_openBlock(), _createElementBlock("img", {
                  key: 1,
                  src: _ctx.suffixIcon,
                  alt: "suffix icon",
                  class: "suffix-icon",
                  onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.suffixAction && _ctx.suffixAction(...args)))
                }, null, 8, _hoisted_4))
              : _createCommentVNode("", true)
          ]))
        : _withDirectives((_openBlock(), _createElementBlock("textarea", {
            key: 1,
            placeholder: _ctx.placeholder,
            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.inputValue) = $event)),
            readonly: _ctx.readonly,
            onFocus: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.onFocus && _ctx.onFocus(...args))),
            onBlur: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.onBlur && _ctx.onBlur(...args))),
            maxlength: _ctx.maxLength != 0 ? _ctx.maxLength : 10000
          }, null, 40, _hoisted_5)), [
            [
              _vModelText,
              _ctx.inputValue,
              void 0,
              { trim: true }
            ]
          ])
    ], 2),
    (_ctx.displayErrorMessage)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          (_ctx.errorMessage && !_ctx.hasFocus)
            ? (_openBlock(), _createBlock(_component_MenuOptionDropdown, {
                key: 0,
                class: "error-tooltip",
                position: "relative",
                "indicator-left": "",
                "indicator-position": "10px"
              }, {
                default: _withCtx(() => [
                  _renderSlot(_ctx.$slots, "default", {
                    errorString: _ctx.getMessage(_ctx.errorMessage)
                  }, () => [
                    _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.getMessage(_ctx.errorMessage)), 1)
                  ], true)
                ]),
                _: 3
              }))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ], 2))
}